
import Select from '../../../component/formfields/Select'
import React, { useState, useEffect, useMemo } from 'react'
import Input from '../../../component/formfields/Input'
import axiosInstance from '../../../utils/axiosInstance'
import DatePicker from "react-datepicker";

function RideEditForm({ data, setData, setEditMode, setInputChanged }) {

  const [cars, setCars] = useState([])
  const [addresses, setAddresses] = useState([])
  const [users, setUsers] = useState([{}])
  const [formData, setFormData] = useState({})


  useEffect(() => {
    // copy data to form data so that form is pre-filled with data
    setFormData({
      car: data.car,
      date: data.date,
      driver: data.driver,
      rideReason: data.rideReason,
      startaddress: data.startaddress.id,
      startmilage: data.startmilage,
      reason: data.reason,
      endaddress: data.endaddress.id,
      endmilage: data.endmilage
    })
  }, [data])



  const handleSubmit = (e) => {
    e.preventDefault()

    axiosInstance.put(`/api/rides/${data.id}/`, formData)
      .then(res => {

        setData(res.data)
        setEditMode(false)
        // window.location.href = "/rides/" + data.id
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })

    // set input changed to true, so user can be notified if they try to leave the page without saving
    setInputChanged(true)

  }


  useEffect(() => {

    axiosInstance.get('/api/cars/')
      .then(res => {
        setCars(res.data)
      })
      .catch(err => {
        console.log(err)
      })

    axiosInstance.get('/api/addresses/')
      .then(res => {
        setAddresses(res.data)
      })
      .catch(err => {
        console.log(err)
      })

    axiosInstance.get(
      "/api/cars/?active=true"
    ).then((res) => {
      setCars(res.data);
    }).catch((err) => {
      console.log(err);
    })

    axiosInstance.get('/api/v1/auth/users/')
      .then((res) => {
        setUsers(res.data)
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  const addressOptions = useMemo(() => (
    // useMemo to generate options for address select dropdown
    addresses.map(item => ({
      value: item.id,
      label: item.name
    }))
  ), [addresses]);

  const carOptions = useMemo(() => (
    // useMemo to generate options for car select dropdown
    cars.map(item => ({
      value: item.id,
      label: item.name
    }))
  ), [cars]);

  const driverOptions = useMemo(() => (
    users.map(item => ({
      value: item.id,
      label: item.email
    }))
  ), [users]);



  return (
    <form onSubmit={e => handleSubmit(e)}>

      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Car<sup>*</sup>:</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Select
                name="car"
                label="Car"
                placeholder="Select car"
                required
                labelDisabled={true}
                onChangeHandler={handleInputChange}
                options={carOptions}
                value={formData.car}
              />
            </dd>
          </div>

          <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Driver<sup>*</sup>:</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Select
                name="driver"
                label="Driver"
                placeholder="Select driver"
                labelDisabled={true}
                onChangeHandler={handleInputChange}
                options={driverOptions}
                value={formData.driver}
              />
            </dd>
          </div>

          <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Date<sup>*</sup>:</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <DatePicker
                selected={formData.date}
                onChange={(d) => setFormData({ ...formData, date: d }, setInputChanged(true))}
                className="w-full px-4 py-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              />
            </dd>
          </div>

          <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Reason:</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Input
                name="reason"
                label="Ride reason"
                type="text"
                text=""
                labelDisabled={true}
                onChangeHandler={handleInputChange}
                value={formData.reason}
              />
            </dd>
          </div>


          <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Start address<sup>*</sup>:</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Select
                name="startaddress" label="Address" placeholder="Select start address"
                required
                labelDisabled={true}
                onChangeHandler={handleInputChange}
                options={addressOptions}
                value={formData.startaddress}
              />
            </dd>
          </div>


          <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Start milage<sup>*</sup>:</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Input
                name="startmilage"
                label="Start milage"
                placeholder="Enter start milage"
                type="number"
                required
                labelDisabled={true}
                onChangeHandler={handleInputChange}
                value={formData.startmilage}
              />
            </dd>
          </div>

          <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">End address<sup>*</sup>:</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Select
                name="endaddress" label="End address" placeholder="Select end address"
                required
                labelDisabled={true}
                onChangeHandler={handleInputChange}
                options={addressOptions}
                value={formData.endaddress}
              />
            </dd>
          </div>


          <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">End milage<sup>*</sup>:</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <Input
                name="endmilage"
                label="End milage"
                placeholder="Enter end milage"
                type="number"
                required
                labelDisabled={true}
                onChangeHandler={handleInputChange}
                value={formData.endmilage}
              />
            </dd>
          </div>


        </dl>
      </div >

      <div className="px-2 py-4 ">

        <div className="flex items-center justify-center mt-2">
          <button
            type="submit"
            id="submit"
            name="submit"
            className="btn bg-orange-500 hover:bg-orange-500 border-orange-600 hover:border-orange-500 text-white rounded-md h-11 justify-center flex items-center"
          >
            Save
          </button>

        </div>
      </div>
    </form >
  )
}

export default RideEditForm