import React from 'react'
import Label from './Label'

function Select(props) {
    return (
        <div className="">
            {!props.labelDisabled &&
                <Label text={props.label ? props.label : props.name} required={props.required} />
            }
            <select className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800"
                name={props.name}
                id={props.id ? props.id : props.name}
                required={props.required ? props.required : false}
                onChange={props.onChangeHandler}
                value={props.value}
                key={props.value}
            >
                {props.omitPlaceholder ? null :
                    <option value="" disabled >{props.placeholder ? props.placeholder : props.name}</option>
                }
                {props.options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            <p className="text-gray-700 text-xs italic">{props.text}</p>
        </div>

    )
}

export default Select