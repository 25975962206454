
import React from 'react';
import BaseTable from '../../../component/tables/BaseTable';
import DeleteButton from '../../../component/buttons/DeleteButton';
import DeleteConfirmButton from '../../../component/buttons/DeleteConfirmButton';

function AddressTable({ addressData, onDeleteHandler }) {
  // table definition for address list, used in AddressList.jsx
  // addressData is an array of address objects
  // onDeleteHandler is a function that handles the delete event


  const columns = [
    {
      header: 'Name',
      accessor: 'name',
      render: (address) => <span className=" whitespace-nowrap">
        <a href={`/addressbook/${address.id}/`}

          className="underline text-orange-500  hover:text-orange-800">
          {address.name}
        </a>
      </span>,
    },
    {
      header: 'Street',
      accessor: 'street_and_number',
      render: (item) => item.street_and_number,
    },
    {
      header: 'City',
      accessor: 'city',
      render: (item) => item.city,
    },
    {
      header: 'Zip',
      accessor: 'zip_code',
      render: (item) => item.zip_code,
    },
    {
      header: 'Country',
      accessor: 'country',
      render: (item) => item.country,
    },
    {
      header: 'Delete',
      accessor: 'id',
      render: (item) => (
        <DeleteConfirmButton text="Delete" deleteHandler={() => onDeleteHandler(item.id)} />

      )
    }
  ];

  return <BaseTable data={addressData} columns={columns} />;
}

export default AddressTable;
