import React, { useState } from 'react'
import DeleteConfirmButton from '../../../component/buttons/DeleteConfirmButton'
import { deleteAddress } from '../../../redux/actions/address'
import { useDispatch } from 'react-redux';

function AddressDetailSection({ addressData }) {
    // section to render address details, used in AddressDetail.jsx
    // addressData is an object with address details

    const dispatch = useDispatch();
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };
   
   
    const handleAddressDelete = (addressId) => {
        // dispatch event so api gets called
        dispatch(deleteAddress(addressId))
        
        // navigate to address list
        window.location.href = "/addressbook";
    }



    return (
        <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Name:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {addressData.name}
                    </dd>
                </div>

                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Street and number</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {addressData.street_and_number}
                    </dd>
                </div>
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Zip code</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {addressData.zip_code}
                    </dd>
                </div>

                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">City</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {addressData.city}
                    </dd>
                </div>

           

                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Country</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {addressData.country}
                    </dd>
                </div>
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Coordinates:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">

                        <span className="whitespace-nowrap">
                            {addressData.latitude}, {addressData.longitude}
                        </span>
                    </dd>
                </div>
                {/* 
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Created:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {addressData.created && new Intl.DateTimeFormat('en-EU', options).format(new Date(addressData.created))}
                    </dd>
                </div>
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Updated:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {addressData.updated && new Intl.DateTimeFormat('en-EU', options).format(new Date(addressData.updated))}
                    </dd>
                </div> */}
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Actions:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <div className="flex">

                            <div className="flex-col">
                            <DeleteConfirmButton text="Delete" deleteHandler={() => handleAddressDelete(addressData.id)} />
                                {/* <DeleteButton
                                    text="Delete"
                                    onClickHandler={() => handleAddressDelete(addressData.id)}
                                    isLoading={false}
                                /> */}
                            </div>

                        </div>
                    </dd>
                </div>
            </dl>
        </div>
    )
}

export default AddressDetailSection