import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Switcher from '../Switcher'
import ToastSection from './ToastSection'

function BasePage({ children }) {
    return (

        <div className="flex flex-col min-h-screen place-content-between ">

            <div className="h-36">
                <Navbar />
                <ToastSection />

            </div>
            <div className="flex-grow">
                {/* <div className="flex flex-col items-center justify-center"> */}
                <div className="container">
                    <div className="card">
                        {/* <MessageDemoSection /> */}
                        {children}
                    </div>

                </div>

            </div>



            <Footer />


            <Switcher />

        </div >

    )
}

export default BasePage