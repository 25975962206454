import React from 'react'

function CompanyDetailSection({ data }) {
    // section to render company details, used in AddressDetail.jsx
    // data is an object with company 


    return (
        <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Name:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {data.name}
                    </dd>
                </div>
            </dl>

            <dl className="divide-y divide-gray-100">
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Nr. of cars:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {data.number_of_cars}
                    </dd>
                </div>
            </dl>

            <dl className="divide-y divide-gray-100">
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Nr. of rides logged:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {data.number_of_rides}
                    </dd>
                </div>
            </dl>

            <dl className="divide-y divide-gray-100">
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Nr. of company members:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {data.number_of_members}
                    </dd>
                </div>
            </dl>

            <dl className="divide-y divide-gray-100">
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Nr. of addresses:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {data.number_of_addresses}
                    </dd>
                </div>
            </dl>
        </div>
    )
}

export default CompanyDetailSection