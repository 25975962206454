
import React, { useState } from 'react';
import { FaMap, FaTable } from 'react-icons/fa';

const Toggle = ({ onChange }) => {
  const [view, setView] = useState('map');

  const handleToggle = (newView) => {
    setView(newView);
    onChange(newView);
  };

  return (
    <div className="flex items-center">
      <button
        className={`${view === 'map' ? 'bg-orange-500' : 'bg-gray-300'
          } hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-l focus:outline-none focus:shadow-outline`}
        onClick={() => handleToggle('map')}
      >
        <FaMap size={20} />
      </button>
      <button
        className={`${view === 'table' ? 'bg-orange-500' : 'bg-gray-300'
          } hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-r focus:outline-none focus:shadow-outline`}
        onClick={() => handleToggle('table')}
      >
        <FaTable size={20} />
      </button>
    </div>
  );
};

export default Toggle;