import React from 'react'

function Label({ text, required }) {
    return (
        <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
            {/* show an asterix if required is true */}
            {text}
            {required && <span className="">*</span>}
        </label>
    )
}

export default Label