import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import BasePage from '../../component/layout/BasePage'
import { useParams } from 'react-router-dom'
import Toggle from '../../component/formfields/Toggle'
import CardetailSection from './components/CardetailSection'
import CarEditForm from './components/CarEditForm'
import { fetchCar } from '../../redux/actions/cars'

function CarDetail() {
    // car detail page main component
    // fetches car data from API and displays it using CardetailSection component
    // allows to edit car data using CarEditForm component

    const { carId } = useParams()
    const dispatch = useDispatch()

    const [carData, setCarData] = useState([]);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            try {
                dispatch(fetchCar(carId))
                    .then(
                        res => {
                            setCarData(res)
                        }
                    )

            } catch (error) {
                console.log(error);
                // Handle error or set an error state here
            }
        };

        fetchData();
    }, [carId]);



    return (
        <BasePage>

            <div className='container'>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Car detail</h3>

                </div>

                {/* right aligned div */}
                <div className="flex justify-end">
                    <Toggle
                        checked={editMode}
                        onChangeHandler={() => setEditMode(currentstate => !currentstate)}
                        label="Edit mode"
                    />
                </div>
                {editMode ? (
                    <CarEditForm carData={carData} setCarData={setCarData} setEditMode={setEditMode} />
                ) : (
                    <CardetailSection carData={carData} />
                )}


            </div>
        </BasePage>
    )
}

export default CarDetail