import React, { useState } from "react";
import { Link } from "react-router-dom";
import CarBg from "../../assets/images/bg/car-bg.jpg";
import Switcher from "../../component/Switcher";
import { useSelector, useDispatch } from "react-redux";
import LogoImage from "../../assets/images/logo-ridetracker.png";
import { useNavigate } from 'react-router-dom';
import { LOGIN_SUCCESS, LOGIN_FAIL } from "../../redux/constants/action-types";
import axiosInstance from "../../utils/axiosInstance";
import { fetchUserData } from "../../redux/actions/auth";

/**
 * Login component
 */
export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    if (isAuthenticated) {
        navigate('/rides');
    }

    const handleLogin = (e) => {
        e.preventDefault();
        // Headers
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        // Request Body
        const body = JSON.stringify({ email, password });

        axiosInstance
            .post(`/api/v1/auth/jwt/create/`, body, config)
            .then((res) => {
                const access = res.data.access;
                const refresh = res.data.refresh;
                localStorage.setItem('access', access);
                localStorage.setItem('refresh', refresh);

                dispatch({
                    type: LOGIN_SUCCESS,
                });
                dispatch(fetchUserData());
            })
            .catch((err) => {
                dispatch({
                    type: LOGIN_FAIL,
                });
                console.log(err);

            });
    }

    return (
        <>
            <section
                style={{ backgroundImage: `url(${CarBg})` }}
                className="position-relative bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-90"></div>
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <div className="lg:col-span-4">
                            <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">


                                <div className=" my-auto">
                                    <div className="mt-5 w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-slate-800">
                                        <div className="grid grid-cols-1">
                                            <div className="text-center mx-auto mb-5">
                                                <Link to="/index"><img src={LogoImage} alt="" className="h-10" /></Link>
                                            </div>
                                            <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Login</h5>
                                            <form className="ltr:text-left rtl:text-right" onSubmit={e => handleLogin(e)}>
                                                <div className="grid grid-cols-1">
                                                    <div className="mb-4">
                                                        <label className="dark:text-white" htmlFor="LoginEmail">Email Address:</label>
                                                        <input
                                                            id="LoginEmail"
                                                            type="email"
                                                            className="form-input mt-3"
                                                            placeholder="name@example.com"
                                                            onChange={e => setEmail(e.target.value)}
                                                            value={email}
                                                        />
                                                    </div>

                                                    <div className="mb-4">
                                                        <label className="dark:text-white" htmlFor="LoginPassword">Password:</label>
                                                        <input
                                                            id="LoginPassword"
                                                            type="password"
                                                            className="form-input mt-3"
                                                            placeholder="Password:"
                                                            onChange={e => setPassword(e.target.value)}
                                                            value={password}
                                                        />
                                                    </div>

                                                    <div className="flex justify-between mb-4">
                                                        <div className="inline-flex items-center">
                                                            {/* <input className="form-checkbox text-orange-600 rounded w-4 h-4 me-2 border border-inherit" type="checkbox" value="" id="RememberMe" /> */}
                                                            {/* <label className="form-check-label text-slate-400" htmlFor="RememberMe">Remember me</label> */}
                                                        </div>

                                                        <p className="text-slate-400 mb-0"><Link to="/reset-password" className="text-orange-600 hover:text-dark dark:text-white dark:hover:text-orange-600">Forgot password ?</Link></p>
                                                    </div>

                                                    <div className="mb-4">
                                                        <button type="submit" className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md w-full">Login</button>
                                                    </div>

                                                    <div className="text-center">
                                                        <span className="text-slate-400 dark:text-slate-300 me-2">Don't have an account ?</span>
                                                        <Link to="/signup" className="text-orange-600 hover:text-dark dark:text-white dark:hover:text-orange-600">Sign Up</Link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Start Footer --> */}
                                <div className="text-center">
                                    <p className="text-gray-400">© {(new Date().getFullYear())}{" "} Ridetracker. Track and manage your rides <i className="mdi mdi-pin text-orange-700"></i></p>

                                </div>
                                {/* <!-- End Footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* Switcher section */}
            < Switcher />
        </>
    )

}
