import React from 'react'
import OcrImage from '../assets/images/rides/OcrImage.jpg'
import UploadImage from '../assets/images/rides/Upload.jpg'


function UploadExplainer() {
    return (
    
        <section className="relative md:py-24 py-16 active" id="features">
            <div className="container md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h6 className="text-orange-600 text-base font-medium uppercase mb-2">Work Process</h6>
                    <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Track Your Rides with Ease</h3>

                    <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Effortlessly keep a record of your car rides by simply uploading a photo of your dashboard.</p>
                </div>
                <div className="grid grid-cols-1 mt-8">
                    <div className="timeline relative">
                        <div className="timeline-item">
                            <div className="grid sm:grid-cols-2">
                                <div className="">
                                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                                        {/* Add an image related to uploading */}
                                        <img src={UploadImage} className="h-64 w-64" alt="Upload" />
                                    </div>
                                </div>
                                <div className="mt-4 md:mt-0">
                                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                                        <h5 className="text-lg dark:text-white mb-1 font-medium">Upload</h5>
                                        <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Start by uploading an image of your car's dashboard to automatically detect your current mileage.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-item mt-5 pt-4">
                            <div className="grid sm:grid-cols-2">
                                <div className="md:order-1 order-2">
                                    <div className="event event-description-left ltr:float-left rtl:float-right ltr:text-right rtl:text-left md:me-7">
                                        <h5 className="text-lg dark:text-white mb-1 font-medium">Detection</h5>
                                        <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Our advanced OCR technology will detect and extract the current mileage from the uploaded image.</p>
                                    </div>
                                </div>
                                <div className="md:order-2 order-1">
                                    <div className="duration duration-right md:ms-7 relative">
                                        {/* Add an image related to OCR */}
                                        <img src={OcrImage} className="h-64 w-64" alt="OCR" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="timeline-item mt-5 pt-4">
                            <div className="grid sm:grid-cols-2">
                                <div className="mt-4 mt-sm-0">
                                    <div className="duration date-label-left ltr:float-right rtl:float-left md:me-7 relative">
                                        {/* Add an image related to ride generation */}
                                        {/* <img src={RideImage} className="h-64 w-64" alt="Ride Generation" /> */}
                                    </div>
                                </div>
                                <div className="mt-4 mt-sm-0">
                                    <div className="event event-description-right ltr:float-left rtl:float-right ltr:text-left rtl:text-right md:ms-7">
                                        <h5 className="text-lg dark:text-white mb-1 font-medium">Ride Generation</h5>
                                        <p className="timeline-subtitle mt-3 mb-0 text-slate-400">Once the mileage is detected, a new ride will be automatically generated based on this data and initial ride points.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default UploadExplainer