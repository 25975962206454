

import React, { useEffect, useState } from 'react'
import BasePage from '../../component/layout/BasePage'
import Toggle from '../../component/formfields/Toggle'
import CompanyDetailSection from './components/CompanyDetailSection'
import axiosInstance from '../../utils/axiosInstance'


function CompanyDetail() {
    // detail page for a single address
    // renders either a AddressDetailSection or AddressEditForm component, depending on editMode state

    const [data, setData] = useState([]);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const getAddress = async () => {
            try {
                axiosInstance.get(`/api/v1/company/me/`)
                    .then(res => {
                        setData(res.data)
                    })
            }
            catch (error) {
                console.log(error);
            }
        }
        getAddress();

    }, []);


    return (
        <BasePage>
            <div className='container'>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Company details</h3>
                </div>
                {/* <div className="flex justify-end">
                    <Toggle
                        checked={editMode}
                        onChangeHandler={() => setEditMode(currentstate => !currentstate)}
                        label="Edit mode"
                    />
                </div> */}

                <CompanyDetailSection data={data} />



            </div>
        </BasePage>
    )
}

export default CompanyDetail

