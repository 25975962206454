import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import PropTypes from 'prop-types';



const MarkerMap = ({ data }) => {
    // component to render a map with markers

    //filter out empty entries
    data = data.filter(item => item.latitude && item.longitude);

    return (
        <MapContainer  center={[52.1326, 5.2913]} zoom={7} style={{ height: '80vh', width: '100%' }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {data && data.length > 0 &&
                data.map((item) => {
                    const { id, latitude, longitude } = item;
                    if (!isNaN(latitude) && !isNaN(longitude)) {
                        return <Marker key={id} position={[parseFloat(latitude), parseFloat(longitude)]} />;
                    }
                    return null;
                })}
        </MapContainer>
    );
};

MarkerMap.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        street_and_number: PropTypes.string.isRequired,
        zip_code: PropTypes.string.isRequired,
        city: PropTypes.string.isRequired,
        country: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number
    })).isRequired
};

export default MarkerMap;
