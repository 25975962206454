import React, { useEffect, useMemo } from 'react'
import BasePage from '../../component/layout/BasePage'
import RideTable from './components/RideTable';
import axiosInstance from '../../utils/axiosInstance';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


function RideList() {

    const [rides, setRides] = React.useState([])
    const [cars, setCars] = React.useState([])
   
    
    useEffect(() => {
        axiosInstance.get('/api/rides/')
            .then((res) => {
                setRides(res.data)
            }
            )
            .catch((err) => {
                console.log(err);}
            )

        axiosInstance.get('/api/cars/')
            .then((res) => {
                setCars(res.data)
            })
            .catch((err) => {
                console.log(err);
            })


    }, [])



    const deleteHandler = (id) => {
  
        axiosInstance.delete(`/api/rides/${id}/`)
            .then((res) => {
                // remove the deleted ride from the list
                setRides(rides.filter((ride) => ride.id !== id))
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <BasePage>
            <section className="relative md:py-24 active" id="features">
                <div className="flex flex-col">
                    <div className="d-flex justify-content-between align-items-center w-full">
                        <div className='mb-4'>
                            <h1 className="text-4xl font-bold">Ride List</h1>
                        </div>
                        <Tabs>
                            <TabList>
                                {cars.map((car) => {
                                    return <Tab key={car.id}>{car.name}</Tab>
                                })}
                            </TabList>

                            {cars.map((car) => {
                                return <TabPanel key={car.id}>
                                    <div className="overflow-x-auto">
                                        <div className="p-1.5 w-full inline-block align-middle">
                                            <div className="overflow-hidden border rounded-lg">
                                                <RideTable rideData={rides.filter((ride) => ride.car === car.id)} deleteHandler={deleteHandler} />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            })}
                        </Tabs>
                        <div className="float-right">
                            <div
                                className='flex items-center justify-center gap-5'
                            >

                            </div>
                        </div>
                    </div>


                </div>
            </section>


        </BasePage>

    )
}

export default RideList