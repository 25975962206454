import axiosInstance from "../../utils/axiosInstance";
import { createToast } from "./toast";

export const fetchCarsList = () => () => {
    // action to fetch all cars
    // returns a promise with the list of cars

    return axiosInstance.get(
        "/api/cars/"
    ).then((res) => {

        return res.data;
    })
}

export const fetchCar = (carId) => () => {
    // action to fetch a car
    // carId is the id of the car to fetch
    // returns a promise with the car data

    return axiosInstance.get(
        `/api/cars/${carId}/`

    ).then((res) => {
        return res.data;
    })
}

export const deleteCar = (carId) => () => {
    // action to delete a car
    // carId is the id of the car to delete


    return axiosInstance.delete(
        `/api/cars/${carId}/`
    ).then((res) => {
        return res.data;
    })
}




export const addCar = (postData) => ()=> {
    // action to add a car
    // postData is an object with car details
    // returns a promise with the added car data
    // actual data from db that was added and return in response

    return axiosInstance.post(
        "/api/cars/", postData,
    ).then((res) => {
       createToast("success", "Car added");
       return res.data;
    })

}


export const editCar = (carId, postData) => () => {
    // action to edit a car
    // postData is an object with car details
    // carId is the id of the car to edit
    // returns a promise with the edited car data
    // actual data from db that was edited and return in response

    return axiosInstance.put(
        `/api/cars/${carId}/`,
        postData
    ).then((res) => {
        return res.data;
    })

}