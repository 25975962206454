

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dismissToast } from '../../redux/actions/toast';


const Toast = ({ message, type, toastId }) => {
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toast.toast);

  const handleDismissToast = () => {
    dispatch(dismissToast(toastId));
  };

  // Function to determine the icon and color based on the status
  const getStatusIconAndColor = () => {
    switch (type) {
      case 'success':
        return {
          icon: (
            <svg
              className="h-6 w-6 text-green-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
            </svg>
          ),
          color: 'bg-green-50 text-green-800 border-green-800', // Updated color class
        };
      case 'error':
        return {
          icon: (
            <svg
              className="h-6 w-6 text-red-400"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          ),
          color: 'bg-red-50 text-red-800 border-maroon-500', // Updated color class
        };
      case 'warning':
        return {
          icon: (
            <svg
              className="h-6 w-6 text-yellow-400"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01M12 17h.01M10 3h4a2 2 0 012 2v10a2 2 0 01-2 2h-4a2 2 0 01-2-2V5a2 2 0 012-2z"
              ></path>
            </svg>
          ),
          color: 'bg-yellow-100 text-yellow-800', // Updated color class
        };
      case 'info':
      default:
        return {
          icon: (
            <svg
              className="h-6 w-6 text-blue-400"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01M12 17h.01M10 3h4a2 2 0 012 2v10a2 2 0 01-2 2h-4a2 2 0 01-2-2V5a2 2 0 012-2z"
              ></path>
            </svg>
          ),
          color: 'bg-blue-100 text-blue-800', // Updated color class
        };
    }
  };

  const { icon, color } = getStatusIconAndColor();

  return (
    <div className={`rounded-md shadow-md p-4 mb-3 ${color} border-2`}>
      <div className="flex items-start">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3">
          <p className="text-sm font-medium text-gray-900">{type}</p>
          <p className="mt-1 text-sm text-gray-600">{message}</p>
        </div>
        <button
          className="ml-auto text-gray-600 hover:text-gray-800 focus:outline-none"
          onClick={() => handleDismissToast()} 
        >
          <svg
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M14.348 5.652a.5.5 0 010 .708L10.707 10l3.641 3.64a.5.5 0 11-.708.708L10 10.707l-3.64 3.641a.5.5 0 01-.708-.708L9.293 10 5.652 6.359a.5.5 0 11.708-.708L10 9.293l3.64-3.641a.5.5 0 01.708 0z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Toast;
