import React from 'react'

function ReturnButton({ text, linkurl }) {
    return (

        <a href={linkurl}
            className="btn w-36 bg-white text-black hover:text-orange-600 hover:border-orange-700 hover:shadow rounded-md h-11 justify-center flex items-center"
       
        >{text}</a>
    )

}

export default ReturnButton