import React from 'react'

function LinkButton({ text, linkurl }) {
    return (
        <a href={linkurl}
            className="btn w-36 bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md cursor-pointer"
        // className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-dark hover:bg-orange focus:outline-orange focus:border-dark focus:shadow-outline-indigo active:bg-dark transition ease-in-out duration-150"
        >{text}</a>
    )
}

export default LinkButton