import React from 'react'
import Label from './Label'

function Input(props) {
  return (
    <div className="">
      {!props.labelDisabled &&
        <Label text={props.label ? props.label : props.name} required={props.required} />
      }
      
      <input className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800"
        name={props.name}
        id={props.id ? props.id : props.name}
        value={props.value ? props.value : ""}
        placeholder={props.placeholder ? props.placeholder : props.name}
        required={props.required ? props.required : false}
        onChange={props.onChangeHandler}
        type={props.type ? props.type : "text"}
        step={props.step ? props.step : "any"}
      />
      <p className="text-gray-700 text-xs italiFc">{props.text}</p>
    </div>
  )
}

export default Input