// RideTable.js
import React from 'react';
import BaseTable from '../../../component/tables/BaseTable';
import { FaTimes, FaCheck } from 'react-icons/fa';

function CarTable({ data }) {
    // table definition for car list 
    // data is an array of car objects

    const columns = [

        {
            header: 'Car',
            accessor: 'name',
            render: (car) => <span className=" whitespace-nowrap">
                <a href={`/cars/${car.id}/`} className="text-orange-600 hover:text-orange-700 dark:text-white dark:hover:text-orange-700 transition-all duration-500 ease-in-out font-medium">
                    {car.name}
                </a>
            </span>,
        },
        {
            header: 'License plate',
            accessor: 'license_plate',
            render: (car) => <span className="whitespace-nowrap">{car.license_plate}</span>,
        },
        {
            header: 'Active',
            accessor: 'active',
            render: (car) =>
                <span className="whitespace-nowrap">
                    {car.active ? (
                        <FaCheck className="text-green" />
                    ) : (

                        <FaTimes className="text-red" />
                    )}
                </span>
        },


    ];

    return <BaseTable data={data} columns={columns} />;
}


export default CarTable;
