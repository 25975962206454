import React from "react";
import { Link as Link2 } from "react-router-dom";
import Typed from "typed.js";
import BackgroudImage from "../assets/images/rides/pexels-jessie-crettenden-3714900.jpg";
import Services from "../component/Services";
import Pricing from "../component/Pricing";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import UploadExplainer from "../component/UploadExplainer";
import Switcher from "../component/Switcher";
import Navbar from "../component/Navbar";



export default function LandingPage() {

  setTimeout(() => {
    new Typed("#typed", {
      strings: [
        "<b>Effortless Milage Management</b>",
        "<b>Smart Ride Solutions</b>",
      ],
      backDelay: 2000,
      loop: true,
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
    });
  }, 500);


  return (
    <>
      <div>

        <Navbar />

        <section
          style={{ backgroundImage: `url(${BackgroudImage})` }}
          className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
          id="home"
        >
          <div className="absolute inset-0 bg-black opacity-80"></div>
          <div className="container relative">
            <div className="grid grid-cols-1 mt-12">
              <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                Transform Your Ride Management <br />
                with{" "}
                <span
                  className="typewrite relative text-type-element"
                  id="typed"
                  data-period="2000"
                  data-type='["Effortless Mileage Management"]'
                ></span>
              </h4>
              <p className="text-white opacity-50 mb-0 max-w-2xl text-lg">
                Experience the convenience of Ridetracker, your companion for seamless car ride tracking and mileage management. You can effortlessly keep store on your journeys, ensuring manage fleets. Say goodbye to manual data entry and hello to the future of hassle-free ride tracking. Discover how Ridetracker can simplify your mileage management today.
              </p>


              <div className="relative mt-10">
                <Link2
                  to="/signup"
                  className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md"
                >
                  Get Started
                </Link2>
              </div>
            </div>
          </div>
        </section>

      

        {/* Service section */}
        <Services />

        <UploadExplainer />

        {/* Pricing section */}
        <Pricing />

        {/* Contact section */}
        <Contact />

        {/* Footer section */}
        <Footer />

        {/* Switcher section */}
        <Switcher />
      </div>

    </>
  );

}

