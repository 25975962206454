
//  original from https://github.com/gitdagray/mern_stack_course/blob/main/lesson_12-frontend/src/features/auth/RequireAuth.js


import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // const isLoading = useSelector((state) => state.auth.isLoading);

  // if (isLoading) {
  //   return <div>Loading...</div>; // Render a loading indicator while verifying authentication status
  // }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Render the protected route if user is authenticated
  return <Outlet />;
};

export default RequireAuth;