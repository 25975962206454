import {
    SHOW_TOAST, 
    REMOVE_TOAST,
} from "../constants/action-types";


export const createToast = (message, type) => (dispatch) => {
    // action to create a toast
    // message is the message to show
    // types: 'success', 'warning', 'error'

    const newToast = {
        id: Date.now(),
        message: message,
        type: type
    };
    dispatch({
        type: SHOW_TOAST,
        payload: newToast,
    });

    // remove toast after 5 seconds
    setTimeout(() => {
        dispatch({
            type: REMOVE_TOAST,
            payload: newToast.id,
        });
    }, 5000);

};


export const dismissToast = (toastId) => (dispatch) => {
   
    dispatch({
      type: REMOVE_TOAST,
      payload: toastId, 
    });
  };
