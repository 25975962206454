import {
    API_CALL_START,
    API_CALL_SUCCESS,
    API_CALL_FAILURE,
  } from '../constants/action-types';
  
  const initialState = {
    isLoading: false,
    error: null,
  };
  
  const apiReducer = (state = initialState, action) => {
    switch (action.type) {
      case API_CALL_START:
        return {
          ...state,
          isLoading: true,
          error: null,
        };
      case API_CALL_SUCCESS:
        return {
          ...state,
          isLoading: false,
          error: null,
        };
      case API_CALL_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default apiReducer;