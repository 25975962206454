import React from 'react'
import { MapContainer, TileLayer, Marker, Polyline, Tooltip } from 'react-leaflet';
import MapComponent from './MapComponent';

function RidedetailSection({ data }) {
    // date options for date formatting
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };

    return (
        <div className="mt-6">
            <div className="flex flex-col lg:flex-row border-b border-t border-gray-200 mb-6">
                <div className="lg:w-2/3 w-full">
                    <div className='p-4 bg-gray-200'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>Car</h3>
                    </div>
                    <div className='p-4 border-l border-r border-gray-200'>
                        <div className='flex '>
                            <div className="pr-6 ">
                                <img
                                    src={data.car && data.car.image}
                                    alt="car"
                                    className=""
                                    onError={(e) => { e.target.onerror = null; e.target.src = "https://via.placeholder.com/150" }}
                                />
                            </div>
                            <dl className="">
                                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Name:</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {data.car ? data.car.name : 'Loading...'}
                                    </dd>
                                </div>

                                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Licence plate:</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                        {data.car ? data.car.license_plate : 'Loading...'}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
                <div className="lg:w-1/3 w-full">
                    <div className='p-4 bg-gray-200'>
                        <h3 className='text-lg font-medium leading-6 text-gray-900'>Ride details</h3>
                    </div>
                    <div className='p-4 border-r border-gray-200'>
                        <dl className="">
                            <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Driver:</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {data.driver_name || 'N/A'}
                                </dd>
                            </div>
                            <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Created by:</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {data.created_by_name || 'N/A'}
                                </dd>
                            </div>

                            <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Distance:</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                    {Math.floor(data.distance)} km
                                </dd>
                            </div>
                            <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Date:</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">

                                    {data.date && new Intl.DateTimeFormat('en-EU', options).format(new Date(data.date))}
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="border border-gray-200 p-4">
                    <h3 className="text-lg font-medium leading-6" style={{}}>Start</h3>
                    <dl className="divide-y divide-gray-100">
                        <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Name:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {data?.startaddressname || 'N/A'}
                            </dd>
                        </div>
                        <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Address:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {data?.startaddressdetails || 'N/A'}
                            </dd>
                        </div>
                        <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Milage:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {data?.startmilage || 'N/A'}
                            </dd>
                        </div>
                    </dl>
                </div>

                <div className="border border-gray-200 p-4">
                    <h3 className="text-lg font-medium leading-6" style={{}}>End</h3>

                    <dl className="divide-y divide-gray-100">
                        <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Name:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {data?.endaddressname || 'N/A'}
                            </dd>
                        </div>
                        <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Address:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {data?.endaddressdetails || 'N/A'}
                            </dd>
                        </div>
                        <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                            <dt className="text-sm font-medium leading-6 text-gray-900">Milage:</dt>
                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                {data?.endmilage || 'N/A'}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>

            <div className="my-8">
                {/* <MapComponent data={data} /> */}
                {/* {data?.startaddress && (
                    <MapContainer center={position1} zoom={7} style={{ height: '80vh', width: '100%' }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker
                            key={0}
                            position={[data?.startaddress.latitude, data?.startaddress.longitude]}
                            color="green"
                        >
                            <Tooltip direction="top" offset={[0, -10]} opacity={1}>
                                <div>
                                    <div>{data?.startaddressname}</div>
                                    <div>{data?.startaddress}</div>

                                </div>
                            </Tooltip>
                        </Marker>

                        <Marker
                            key={1}
                            position={[data?.endaddress.latitude, data?.endaddress.longitude]}
                            color="green"
                        >
                            <Tooltip direction="top" offset={[0, -10]} opacity={1}>
                                <div>
                                    <div>{data?.endaddressname}</div>
                                    <div>{data?.endaddress}</div>

                                </div>
                            </Tooltip>
                        </Marker>
                        <Polyline positions={
                            [
                                [data?.startaddress.latitude, data?.startaddress.longitude],
                                [data?.endaddress.latitude, data?.endaddress.longitude]
                            ]} color="blue" />
                    </MapContainer>
                )} */}
            </div>
        </div>
    )
}

export default RidedetailSection