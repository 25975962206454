// RideTable.js
import React from 'react';
import BaseTable from '../../../component/tables/BaseTable';
import EditButton from '../../../component/buttons/EditButton';
import DeleteConfirmButton from '../../../component/buttons/DeleteConfirmButton';

function RideTable({rideData, deleteHandler}){
    // ride table definition for use in RideList.jsx
    // renders a table of rides with the following columns: 

    const columns = [
        {
            header: 'Driver',
            render: (item) =>  item.driver_name,
        },
        {
            header: 'Date',
            accessor: 'date',
        },
        {
            header: 'Start ',
            accessor: 'startaddressname',
        },
        {
            header: 'End',
            accessor: 'endaddressname',
        },
        {
            header: 'Distance',
            accessor: 'distance',
            render: (item) => Math.round(item.distance),
        },
        {
            header: 'Start milage',
            accessor: 'startmilage',
            render: (item) => Math.round(item.startmilage),
        },
        {
            header: 'End milage',
            accessor: 'endmilage',
            render: (item) => Math.round(item.endmilage),
        },
        {
            header: 'Details',
            accessor: 'details',
            render: (item) => (
                <EditButton  text="details" linkurl={`/rides/${item.id}`} />
            ),
        }, 
        {
            header: 'Delete',
            accessor: 'delete',
            render: (item) => (
                <DeleteConfirmButton id={item.id} deleteHandler={deleteHandler} />
            ),
        }
    ];

    return <BaseTable data={rideData} columns={columns} />;
}


export default RideTable;
