import React from 'react'
import { useSelector } from 'react-redux'
import Toast from '../common/Toast'

function ToastSection() {
    const toasts = useSelector((state) => state.toast.toasts);
   
    return (
        <div className="toast-container">
            <div
                className={`fixed top-12 right-0 m-4 z-50 toast-container `}
            >
                {toasts.map((toast) => (
                    <Toast key={toast.id} type={toast.type} message={toast.message} toastId={toast.id}/>
                ))}
            </div>
            {/* Example buttons to trigger different types of toasts */}
            {/* <button
                onClick={() => {
                    dispatch(createToast("Success toast", "success"));
                }}
            >
                Show Success Toast
            </button>
            <button
                onClick={() => {
                    dispatch(createToast("Error toast", "error"));
                }}
            >
                Show Error Toast
            </button> */}
        </div >
    );
}

export default ToastSection;