import React from 'react'
import BasePage from '../component/layout/BasePage'
import Services  from '../component/Services'


function FeaturesPage() {

    return (
        <BasePage>
            {/* Start */}
           <Services />
        </BasePage >
    )
}

export default FeaturesPage