import store from '../redux/store';
import { createToast } from '../redux/actions/toast';


const handle400Errors = (error) => {

  if (error.response && error.response.status === 400) {
    const errors = error.response.data;
    if (errors && typeof errors === 'object') {
      Object.entries(errors).forEach(([field, message]) => {
        store.dispatch(createToast(message, 'error'));
      });
    }
  }
};

export default handle400Errors;